<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingClassification',
  components: {
    Acknowledgement: lazyLoad('views/Onboarding/Classification/Acknowledgement'),
    Conditions: lazyLoad('views/Onboarding/Classification/Conditions'),
    InvestorClassification: lazyLoad('views/Onboarding/Classification/InvestorClassification'),
    Overview: lazyLoad('views/Onboarding/Classification/Documents'),
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    component() {
      return this.$route.params.step;
    },
  },
  watch: {
    subStage(step) {
      if (step !== 'Information') {
        this.$router.push({
          name: 'Onboarding.Classification',
          params: {
            step,
          },
        });
      }
    },
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setMainStage',
      'setSubStage',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async submitForm() {
      this.setLoading(true);
      try {
        await this.$refs.form.submit();
        this.setLoading(false);
      } catch (error) {
        this.setLoading(false);
        throw new Error(error);
      }
    },
    async next() {
      if (this.component !== 'Overview') {
        return this.submitForm();
      }
      await this.submitForm();
      return this.$router.push({
        name: 'Onboarding.Overview',
      });
    },
  },
};
</script>
